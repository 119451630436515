<template>
  <v-dialog v-model="isActive" persistent :width="width">
    <v-card v-show="step === 0">
      <v-toolbar flat color="black" dark>
        <v-toolbar-title> Wybierz przedmiot lub zestaw </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="py-5 grey--text text--darken-4">
        <v-row justify="center" align="center">
          <v-col cols="12">
            <v-btn block dark x-large @click="step = 1"> Przedmiot </v-btn>
          </v-col>

          <v-col cols="12">
            <v-btn block dark x-large @click="step = 2"> Zestaw </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="isActive = false"
        >
          Anuluj
        </v-btn>

        <v-spacer />
      </v-card-actions>
    </v-card>

    <v-card v-if="step === 1">
      <v-toolbar flat color="black" dark>
        <v-toolbar-title> Wybierz przedmiot i jego wariant </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="pt-5 pb-1 grey--text text--darken-4">
        <v-row justify="center" align="center" no-gutters>
          <v-autocomplete
            v-model="selectedItem"
            :items="items"
            :item-text="autoCompleteItemTextFormater"
            return-object
            label="Przedmiot"
            auto-select-first
            outlined
            :rules="[v => !!v || 'Wybierz przedmiot z listy']"
            @change="isItemWithSingleVariant"
          >
          </v-autocomplete>
        </v-row>

        <v-row v-if="selectedItem" justify="center" align="center" no-gutters>
          <v-select
            v-model="selectedItemVariant"
            :items="selectedItem.variants"
            :item-text="autoCompleteVariantTextFormater"
            return-object
            label="Wariant"
            auto-select-first
            outlined
            :rules="[v => !!v || 'Wybierz wariant z listy']"
          >
          </v-select>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="
            step = 0;
            selectedItemVariant = null;
            selectedItem = null;
          "
        >
          Wstecz
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          v-tilt
          x-large
          @click="addItemToList"
        >
          Dodaj
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="step === 2">
      <v-toolbar flat color="black" dark>
        <v-toolbar-title> Wybierz zestaw i warianty przedmiotów </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="pt-5 pb-1 grey--text text--darken-4">
        <v-row justify="center" align="center" no-gutters>
          <v-autocomplete
            v-model="selectedSet"
            :items="sets"
            :item-text="autoCompleteSetTextFormater"
            return-object
            label="Wybierz przedmiot z listy"
            auto-select-first
            outlined
            :rules="[v => !!v || 'Wybierz przedmiot z listy']"
            @change="isSetWithSingleVariantItems"
          >
          </v-autocomplete>
        </v-row>

        <v-row
          v-for="(elem, index) in selectedSetItemsVariants"
          :key="index"
          justify="center"
          align="center"
          no-gutters
        >
          <v-select
            v-model="selectedSetItemsVariants[index]"
            :items="selectedSet.items[index].item.variants"
            :item-text="autoCompleteVariantTextFormater"
            return-object
            :label="autoCompleteItemTextFormater(selectedSet.items[index].item)"
            auto-select-first
            outlined
            :rules="[v => !!v || 'Wybierz wariant z listy']"
          >
          </v-select>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="
            step = 0;
            selectedSetItemsVariants = [];
            selectedSet = null;
          "
        >
          Wstecz
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          v-tilt
          x-large
          @click="addSetToList"
        >
          Dodaj
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'AddItemSetToTransportDialog',
    components: {},
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      items: {
        type: Array,
        default: () => [],
      },
      sets: {
        type: Array,
        default: () => [],
      },
      categories: {
        type: Object,
        default: () => ({
          items: [{ name: 'Ogólna', category_id: null }],
          sets: [{ name: 'Ogólna', category_id: null }],
        }),
      },
      width: {
        type: String,
        default: '820px',
      },
    },
    data: () => ({
      step: 0,

      selectedItem: null,
      selectedItemVariant: null,

      selectedSet: null,
      selectedSetItemsVariants: [],
    }),
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
    watch: {
      value: function (val) {
        if (val === false)
          this.$nextTick(() => {
            this.step = 0;
            this.selectedSetItemsVariants = [];
            this.selectedSet = null;
            this.selectedItemVariant = null;
            this.selectedItem = null;
          });
      },
    },
    methods: {
      infoSnackBar(info) {
        this.$emit('info', {
          message: info.message || '',
          status: info.status || 'error',
          timeout: info.timeout,
        });
      },

      autoCompleteItemTextFormater: function (item) {
        return `${item.name}  (${
          this.categories.items.find(elem => elem.category_id === item.category_id).name
        })`;
      },
      autoCompleteVariantTextFormater: function (variant) {
        return `${variant.variant_name}${variant.SKU ? ' (' + variant.SKU + ')' : ''} ${
          variant.packages.length
        } pck.`;
      },
      autoCompleteSetTextFormater: function (set) {
        return `${set.name}  ${
          this.categories.sets.find(elem => elem.category_id === set.category_id).name
        }`;
      },
      isItemWithSingleVariant: function (item) {
        if (item?.variants.length === 1) this.selectedItemVariant = item.variants[0];
        else this.selectedItemVariant = null;
      },
      isSetWithSingleVariantItems: function (set) {
        if (set?.items?.length) {
          const tmp = new Array(set.items.length).fill(null);

          for (let index = 0; index < tmp.length; index++)
            if (set.items[index]?.item.variants.length === 1)
              tmp[index] = set.items[index].item.variants[0];

          this.selectedSetItemsVariants = tmp;
        } else this.selectedSetItemsVariants = [];
      },

      addItemToList: function () {
        if (!this.selectedItem) return this.infoSnackBar({ message: 'Wybierz przedmiot' });
        if (!this.selectedItemVariant) return this.infoSnackBar({ message: 'Wybierz wariant' });

        this.$emit(
          'add',
          Object.assign(
            {
              quantity: 1,
              selectedColor: this.selectedItemVariant.color[0] || '',
              selectedVariant: this.selectedItem.variants.indexOf(this.selectedItemVariant),
            },
            JSON.parse(JSON.stringify(this.selectedItem))
          ),
          'items'
        );
      },
      addSetToList: function () {
        if (!this.selectedSet) return this.infoSnackBar({ message: 'Wybierz zestaw' });
        if (
          !this.selectedSetItemsVariants ||
          !this.selectedSetItemsVariants.length ||
          this.selectedSetItemsVariants.indexOf(null) !== -1
        )
          return this.infoSnackBar({ message: 'Wybierz warianty dla wszystkich pól' });

        const formatedCopyOfItems = this.selectedSet.items.map((elem, index) => {
          const selectedVariantIndex = elem.item.variants.indexOf(
            this.selectedSetItemsVariants[index]
          );

          return Object.assign(elem, {
            item: Object.assign(
              {
                quantity: 1,
                selectedColor: elem.item.variants[selectedVariantIndex].color[0] || '',
                selectedVariant: selectedVariantIndex,
              },
              JSON.parse(JSON.stringify(elem.item))
            ),
          });
        });

        // quantity too
        this.$emit(
          'add',
          {
            quantity: 1,
            category_id: this.selectedSet.category_id,
            items: formatedCopyOfItems,
            name: this.selectedSet.name,
            set_id: this.selectedSet.set_id,
            user_id: this.selectedSet.user_id,
          },
          'sets'
        );
      },
    },
    mounted() {},
    created() {},
  };
</script>

<style>
  div.v-select__slot div.v-input__append-inner {
    align-self: auto;
  }
</style>
