<template>
  <v-container fluid class="grey lighten-4">
    <v-card elevation="4" outlined>
      <v-card-title class="display-1"> Dodaj transport </v-card-title>

      <v-card-text class="mt-4">
        <h3 class="grey--text text--darken-2">
          <v-icon> {{ stepData[step].icon }} </v-icon>
          {{ stepData[step].title }}
        </h3>
        <br />

        <v-form ref="zeroForm" v-model="stepData[0].validForm" class="indent-1" v-show="step === 0">
          <v-row justify="center">
            <v-col cols="12" sm="6" md="6" lg="6">
              <h4>Sposób rozliczenia z klientem końcowym:</h4>

              <v-radio-group v-model="stepData[0].fields.payed_in_advance">
                <v-radio :value="true" label="Transport opłacony" color="amber"></v-radio>
                <v-radio :value="false" label="Transport za pobraniem" color="amber"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <h4>Sposób dostarczenia przedmiotów zlecenia:</h4>

              <v-radio-group v-model="stepData[0].fields.paid_collection">
                <v-radio
                  value="warehouse"
                  label="Nadanie w siedzibie (dostarczenie na nasz magazyn)"
                  color="amber"
                ></v-radio>
                <v-radio
                  v-if="customer.paid_collection"
                  value="pickup"
                  label="Odbiór z wyznaczonego adresu (dodatkowo płatne)"
                  color="amber"
                ></v-radio>
                <v-radio
                  value="manufactory"
                  label="Odbiór od wybranych producentów"
                  color="amber"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col
              v-if="stepData[0].fields.payed_in_advance === false"
              :align="isMobile ? 'start' : 'center'"
              cols="12"
              sm="12"
              md="12"
              lg="12"
            >
              <v-text-field
                v-model="stepData[0].fields.money_to_collect"
                min="0"
                label="Kwota pobrania"
                name="money"
                type="number"
                suffix="zł"
                :rules="rules.moneyToCollect"
                dense
              />
            </v-col>

            <v-col
              v-if="stepData[0].fields.paid_collection === 'warehouse'"
              :align="isMobile ? 'start' : 'center'"
              cols="12"
              sm="12"
              md="12"
              lg="12"
            >
              <h4>
                <!-- <span class="font-weight-black text-uppercase">Wybierz</span> -->
                Wybierz termin dostarczenia towaru na nasz magazyn:
              </h4>

              <h4 class="font-weight-regular">Realizowane tylko do godziny 15</h4>

              <v-date-picker
                v-model="stepData[0].fields.pickup_date"
                color="amber darken-1"
                :first-day-of-week="1"
                locale="pl-PL"
                :allowed-dates="doNotAllowWeekendsAndTodayAfter15"
                elevation="10"
              ></v-date-picker>
            </v-col>

            <v-col
              v-if="stepData[0].fields.paid_collection === 'manufactory'"
              :align="isMobile ? 'start' : 'center'"
            >
              <h4>Wybierz producenta spośród listy dostępnych producentów:</h4>

              <v-autocomplete
                v-model="stepData[0].fields.manufactory"
                :items="manufactories"
                item-text="name"
                label="Wybierz producenta z listy"
                return-object
                auto-select-first
                solo
                :rules="[
                  v => !!v || 'Wybierz producenta lub zmień sposób dostarczenia przedmiotów',
                ]"
              >
                <template v-slot:selection="{ item }">
                  {{
                    item.name
                      ? `${item.name} ${item.city ? '(' + item.city + ')' : ''} ${item.price} zł`
                      : ''
                  }}
                </template>
                <template v-if="!isMobile" v-slot:item="{ item }">
                  <template>
                    <v-list-item-title style="max-width: 35%">
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-title>
                      {{ item.postcode }} {{ item.city }}, {{ item.street }}
                    </v-list-item-title>
                    <v-list-item-title style="max-width: fit-content">
                      {{ item.price }} zł
                    </v-list-item-title>
                  </template>
                </template>
                <template v-else v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-title>
                      {{ item.postcode }} {{ item.city }}, {{ item.street }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-title style="max-width: fit-content">
                    {{ item.price }} zł
                  </v-list-item-title>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>

        <v-form ref="oneForm" v-model="stepData[1].validForm" class="indent-1" v-show="step === 1">
          <v-row justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-data-table
                :headers="stepData[1].fields.headers"
                :items="addedItemsAndSets"
                item-key="index"
                :expanded.sync="stepData[1].fields.expanded"
                show-expand
                class="elevation-3"
                disable-filtering
                disable-sort
                hide-default-footer
                disable-pagination
                dense
                no-data-text="Lista jest pusta"
                :items-per-page="-1"
              >
                <template v-slot:top>
                  <v-toolbar flat dense>
                    <v-toolbar-title> Lista przedmiotów zlecenia </v-toolbar-title>
                    <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.category_id`]="{ item }">
                  {{ item.set_id ? 'Zestaw' : 'Przedmiot' }}
                </template>

                <!-- <template v-slot:[`item.item_type`]="{ item }">
                  {{ itemTypeToText(item.item_type) }}
                </template> -->

                <template v-slot:[`item.quantity`]="{ item }">
                  <v-edit-dialog :return-value="item.quantity">
                    {{ item.quantity }}
                    <v-icon small color="primary"> mdi-pencil </v-icon>

                    <template v-slot:input>
                      <v-text-field
                        v-model="item.quantity"
                        single-line
                        label="Ilość"
                        :rules="rules.quantity"
                        type="number"
                        max="9999"
                        min="1"
                        dense
                        autofocus
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                  <template v-if="item.set_id">
                    {{ `${item.name}` }}
                    ({{
                      categories.sets.length &&
                      categories.sets.find(el => el.category_id == item.category_id)
                        ? categories.sets.find(el => el.category_id == item.category_id).name
                        : '?'
                    }})
                  </template>

                  <template v-else-if="item.variants.length <= 1">
                    {{ `${item.name}` }}
                    ({{
                      categories.items.length
                        ? categories.items.find(el => el.category_id == item.category_id).name
                        : '?'
                    }})
                    {{ '' }}
                    {{ variantFormatName(item.variants[item.selectedVariant]) }}
                  </template>

                  <template v-else>
                    <v-edit-dialog :return-value="item.selectedVariant">
                      {{ `${item.name}` }}
                      ({{
                        categories.items.length
                          ? categories.items.find(el => el.category_id == item.category_id).name
                          : '?'
                      }})
                      {{ '/' }}
                      {{ variantFormatName(item.variants[item.selectedVariant]) }}
                      <v-btn icon x-small color="primary">
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>

                      <template v-slot:input>
                        <v-select
                          v-model="item.selectedVariant"
                          :items="
                            item.variants.map((elem, index) => Object.assign(elem, { index }))
                          "
                          :item-text="variantFormatName"
                          item-value="index"
                          hide-selected
                          dense
                          @change="changeVariant($event, item)"
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>
                </template>

                <template v-slot:[`item.color`]="{ item }">
                  <template
                    v-if="item.set_id || item.variants[item.selectedVariant].color.length <= 1"
                  >
                    {{
                      item.set_id || !item.variants[item.selectedVariant].color.length
                        ? ''
                        : item.selectedColor
                    }}
                  </template>

                  <template v-else>
                    <v-edit-dialog :return-value="item.selectedColor">
                      {{ item.selectedColor }}
                      <v-btn icon x-small color="primary">
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>

                      <template v-slot:input>
                        <v-select
                          v-model="item.selectedColor"
                          :items="item.variants[item.selectedVariant].color"
                          hide-selected
                          dense
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td v-if="item.set_id" :colspan="headers.length" class="ma-n1 pa-0">
                    <v-data-table
                      :headers="stepData[1].fields.innderHeaders"
                      :items="item.items"
                      disable-filtering
                      disable-sort
                      hide-default-footer
                      disable-pagination
                      dense
                      no-data-text="Brak przypisanych przedmiotów"
                      :items-per-page="-1"
                      style="padding-left: 12%"
                    >
                      <template v-slot:[`item.quantity`]="{ item }">
                        {{ item.quantity }}
                      </template>

                      <template v-slot:[`item.name`]="{ item }">
                        <template v-if="item.item.variants.length <= 1">
                          {{ `${item.item.name}` }}
                          ({{
                            categories.items.length
                              ? categories.items.find(el => el.category_id == item.item.category_id)
                                  .name
                              : '?'
                          }})
                          {{ '' }}
                          {{ variantFormatName(item.item.variants[item.item.selectedVariant]) }}
                        </template>

                        <template v-else>
                          <v-edit-dialog :return-value="item.item.selectedVariant">
                            {{ `${item.item.name}` }}
                            ({{
                              categories.items.length
                                ? categories.items.find(
                                    el => el.category_id == item.item.category_id
                                  ).name
                                : '?'
                            }})
                            {{ variantFormatName(item.item.variants[item.item.selectedVariant]) }}
                            <v-btn icon x-small color="primary">
                              <v-icon> mdi-pencil </v-icon>
                            </v-btn>

                            <template v-slot:input>
                              <v-select
                                v-model="item.item.selectedVariant"
                                :items="
                                  item.item.variants.map((elem, index) =>
                                    Object.assign(elem, { index })
                                  )
                                "
                                :item-text="variantFormatName"
                                item-value="index"
                                hide-selected
                                dense
                                @change="changeVariant($event, item.item)"
                              ></v-select>
                            </template>
                          </v-edit-dialog>
                        </template>
                      </template>

                      <template v-slot:[`item.color`]="{ item }">
                        <template
                          v-if="item.item.variants[item.item.selectedVariant].color.length <= 1"
                        >
                          {{ item.selectedColor }}
                        </template>

                        <template v-else>
                          <v-edit-dialog :return-value="item.item.selectedColor">
                            {{ item.item.selectedColor }}
                            <v-btn icon x-small color="primary">
                              <v-icon> mdi-pencil </v-icon>
                            </v-btn>

                            <template v-slot:input>
                              <v-select
                                v-model="item.item.selectedColor"
                                :items="item.item.variants[item.item.selectedVariant].color"
                                hide-selected
                                dense
                              ></v-select>
                            </template>
                          </v-edit-dialog>
                        </template>
                      </template>
                    </v-data-table>
                  </td>
                </template>

                <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded, item }">
                  <v-btn
                    icon
                    x-small
                    color="error"
                    @click="removeFromList(item.set_id ? 'sets' : 'items', item)"
                  >
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>

                  <v-btn v-if="item.set_id" icon small color="primary" @click="expand(!isExpanded)">
                    <v-icon> {{ `mdi-chevron-${isExpanded ? 'up' : 'down'}` }} </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" align="center">
              <v-btn color="primary" width="294px" @click="addItemSetToTransportDialog = true">
                Wybierz z moich produktów
                <v-spacer />
                <v-icon right> mdi-plus </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" align="center">
              <v-btn color="primary" width="294px" @click="addEditItemDialog = true">
                Dodaj z ogólnego cennika
                <v-spacer />
                <v-icon right> mdi-plus </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                v-model="stepData[1].fields.extension"
                maxlength="64"
                label="Numer własny zlecenia (opcjonalne)"
                name="extension"
                type="text"
                dense
              />
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <v-textarea
                v-model="stepData[1].fields.info"
                name="info"
                label="Informacje dodatkowe (opcjonalne)"
                maxlength="1000"
                rows="3"
                dense
              />
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <p class="v-label theme--light">Załącz pliki do zamówienia (opcjonalne)</p>

              <vue-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                v-on:vdropzone-sending="onSend"
                v-on:vdropzone-success="onSuccess"
              ></vue-dropzone>
            </v-col>
          </v-row>
        </v-form>

        <v-form ref="twoForm" v-model="stepData[2].validForm" class="indent-1" v-show="step === 2">
          <v-row justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                v-model="stepData[2].fields.name"
                :rules="rules.notEmpty"
                maxlength="150"
                label="Imię i nazwisko lub nazwa firmy"
                name="fullName"
                type="text"
                dense
              />
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12" class="pb-0">
              <vue-tel-input
                class="mb-2"
                v-model="stepData[2].fields.phone_1"
                autoFormat
                :allCountries="telOptions.avaliableCountries"
                defaultCountry="PL"
                :inputOptions="telOptions.input_1"
                :dropdownOptions="telOptions.dropdown"
                mode="auto"
                :validCharactersOnly="false"
                @validate="telValidationSetter($event, 2, 1)"
                @country-changed="telCountryChanged($event, 2, 1)"
              ></vue-tel-input>
              <div class="v-text-field__details">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ stepData[2].fields.errorMessage_1 }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <vue-tel-input
                class="mb-2"
                v-model="stepData[2].fields.phone_2"
                autoFormat
                :allCountries="telOptions.avaliableCountries"
                defaultCountry="PL"
                :inputOptions="telOptions.input_2"
                :dropdownOptions="telOptions.dropdown"
                mode="auto"
                :validCharactersOnly="false"
                @validate="telValidationSetter($event, 2, 2, false)"
                @country-changed="telCountryChanged($event, 2, 2, false)"
              ></vue-tel-input>
              <div class="v-text-field__details">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ stepData[2].fields.errorMessage_2 }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="4" md="4" lg="4">
              <v-text-field
                v-model="stepData[2].fields.postcode"
                :rules="rules.postcode"
                v-mask="'##-###'"
                label="Kod pocztowy"
                name="postcode"
                type="text"
                dense
              />
            </v-col>

            <v-col cols="12" sm="8" md="8" lg="8">
              <v-text-field
                v-model="stepData[2].fields.city"
                :rules="rules.notEmpty"
                maxlength="150"
                label="Miasto"
                name="city"
                type="text"
                dense
              />
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                v-model="stepData[2].fields.street"
                :rules="rules.notEmpty"
                maxlength="150"
                label="Ulica i numer mieszkania"
                name="street"
                type="text"
                dense
              />
            </v-col>
          </v-row>
        </v-form>

        <v-form
          ref="threeForm"
          v-model="stepData[3].validForm"
          class="indent-1"
          v-show="step === 3"
        >
          <v-row justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                v-model="stepData[3].fields.name"
                :rules="rules.notEmpty"
                maxlength="150"
                label="Imię i nazwisko lub nazwa firmy"
                name="fullName"
                type="text"
                dense
              />
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12" class="pb-0">
              <vue-tel-input
                class="mb-2"
                v-model="stepData[3].fields.phone_1"
                autoFormat
                :allCountries="telOptions.avaliableCountries"
                defaultCountry="PL"
                :inputOptions="telOptions.input_1"
                :dropdownOptions="telOptions.dropdown"
                mode="auto"
                :validCharactersOnly="false"
                @validate="telValidationSetter($event, 3, 1)"
                @country-changed="telCountryChanged($event, 3, 1)"
              ></vue-tel-input>
              <div class="v-text-field__details">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ stepData[3].fields.errorMessage_1 }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <vue-tel-input
                class="mb-2"
                v-model="stepData[3].fields.phone_2"
                autoFormat
                :allCountries="telOptions.avaliableCountries"
                defaultCountry="PL"
                :inputOptions="telOptions.input_2"
                :dropdownOptions="telOptions.dropdown"
                mode="auto"
                :validCharactersOnly="false"
                @validate="telValidationSetter($event, 3, 2, false)"
                @country-changed="telCountryChanged($event, 3, 2, false)"
              ></vue-tel-input>
              <div class="v-text-field__details">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ stepData[3].fields.errorMessage_2 }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="4" md="4" lg="4">
              <v-text-field
                v-model="stepData[3].fields.postcode"
                :rules="rules.postcode"
                v-mask="'##-###'"
                label="Kod pocztowy"
                name="postcode"
                type="text"
                dense
              />
            </v-col>

            <v-col cols="12" sm="8" md="8" lg="8">
              <v-text-field
                v-model="stepData[3].fields.city"
                :rules="rules.notEmpty"
                maxlength="150"
                label="Miasto"
                name="city"
                type="text"
                dense
              />
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                v-model="stepData[3].fields.street"
                :rules="rules.notEmpty"
                maxlength="150"
                label="Ulica i numer mieszkania"
                name="street"
                type="text"
                dense
              />
            </v-col>
          </v-row>
        </v-form>

        <v-form ref="fourForm" v-model="stepData[4].validForm" class="indent-1" v-show="step === 4">
          <v-row justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-checkbox
                v-model="stepData[4].fields.bill"
                label="Chcę otrzymać paragon zamiast faktury"
                color="primary"
                hide-details
                true-value
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row justify="center" v-if="!stepData[4].fields.bill">
            <v-col cols="12" sm="12" md="12" lg="12" v-if="!stepData[4].fields.editInvoice">
              <v-btn
                :color="stepData[4].fields.editInvoice ? 'success' : 'info'"
                @click="$set(stepData[4].fields, 'editInvoice', !stepData[4].fields.editInvoice)"
              >
                {{
                  stepData[4].fields.editInvoice
                    ? 'Skończ edycje danych do faktury'
                    : 'Edytuj dane do faktury'
                }}
                <v-icon right>
                  {{
                    stepData[4].fields.editInvoice ? 'mdi-check-outline' : 'mdi-circle-edit-outline'
                  }}
                </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                v-model="stepData[4].fields.name"
                :rules="rules.notEmpty"
                maxlength="150"
                label="Nazwa firmy"
                name="fullName"
                type="text"
                dense
                :disabled="!stepData[4].fields.editInvoice"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                v-model="stepData[4].fields.NIP"
                :rules="rules.validNIP"
                v-mask="'##########'"
                label="NIP"
                name="nip"
                type="text"
                dense
                :disabled="!stepData[4].fields.editInvoice"
              />
            </v-col>

            <v-col cols="12" sm="4" md="4" lg="4">
              <v-text-field
                v-model="stepData[4].fields.postcode"
                :rules="rules.postcode"
                v-mask="'##-###'"
                label="Kod pocztowy"
                name="postcode"
                type="text"
                dense
                :disabled="!stepData[4].fields.editInvoice"
              />
            </v-col>

            <v-col cols="12" sm="8" md="8" lg="8">
              <v-text-field
                v-model="stepData[4].fields.city"
                :rules="rules.notEmpty"
                maxlength="150"
                label="Miasto"
                name="city"
                type="text"
                dense
                :disabled="!stepData[4].fields.editInvoice"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                v-model="stepData[4].fields.street"
                :rules="rules.notEmpty"
                maxlength="150"
                label="Ulica i numer budynku"
                name="street"
                type="text"
                dense
                :disabled="!stepData[4].fields.editInvoice"
              />
            </v-col>
          </v-row>
        </v-form>

        <v-form ref="fiveForm" v-model="stepData[5].validForm" class="indent-1" v-show="step === 5">
          <v-card class="mx-auto" max-width="600">
            <v-card-text>
              <p class="text-h4 text--primary">Dostawa</p>

              <v-row justify="center" class="text--primary font-weight-medium" no-gutters>
                <v-col cols="12" sm="6" md="6" lg="6">
                  {{ stepData[3].fields.name }}
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  + {{ stepData[3].fields.dialCode_1 }}
                  {{ stepData[3].fields.phone_1 }}
                </v-col>

                <v-col cols="0" sm="6" md="6" lg="6" v-if="stepData[3].fields.phone_2.length">
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" v-if="stepData[3].fields.phone_2.length">
                  + {{ stepData[3].fields.dialCode_2 }}
                  {{ stepData[3].fields.phone_2 }}
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  {{ stepData[3].fields.postcode }}
                  {{ stepData[3].fields.city }}
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  {{ stepData[3].fields.street }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            v-if="stepData[0].fields.paid_collection !== 'warehouse'"
            class="mx-auto mt-4"
            max-width="600"
          >
            <v-card-text v-if="stepData[0].fields.paid_collection === 'pickup'">
              <p class="text-h4 text--primary">Odbiór</p>

              <v-row justify="center" class="text--primary font-weight-medium" no-gutters>
                <v-col cols="12" sm="6" md="6" lg="6">
                  {{ stepData[2].fields.name }}
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  + {{ stepData[2].fields.dialCode_1 }}
                  {{ stepData[2].fields.phone_1 }}
                </v-col>

                <v-col cols="0" sm="6" md="6" lg="6" v-if="stepData[2].fields.phone_2.length">
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" v-if="stepData[2].fields.phone_2.length">
                  + {{ stepData[2].fields.dialCode_2 }}
                  {{ stepData[2].fields.phone_2 }}
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  {{ stepData[2].fields.postcode }}
                  {{ stepData[2].fields.city }}
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  {{ stepData[2].fields.street }}
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-else>
              <p class="text-h4 text--primary">Odbiór (producent)</p>

              <v-row justify="center" class="text--primary font-weight-medium" no-gutters>
                <v-col cols="12" sm="6" md="6" lg="6">
                  {{ stepData[0].fields.manufactory.name }}
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  {{ stepData[0].fields.manufactory.postcode }}
                  {{ stepData[0].fields.manufactory.city }}
                </v-col>

                <v-col cols="12" sm="12" md="12" lg="12">
                  {{ stepData[0].fields.manufactory.street }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mx-auto mt-4" max-width="600">
            <v-card-text>
              <p class="text-h4 text--primary">Przedmiot zlecenia</p>

              <v-row justify="center" class="text--primary font-weight-medium" no-gutters>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-data-table
                    :headers="stepData[1].fields.headers"
                    :items="addedItemsAndSets"
                    :expanded="addedItemsAndSets"
                    show-expand
                    class="elevation-0"
                    disable-filtering
                    disable-sort
                    hide-default-footer
                    :hide-default-header="!isMobile"
                    disable-pagination
                    dense
                    no-data-text="Lista jest pusta"
                    expand-icon=""
                    :items-per-page="-1"
                  >
                    <template v-slot:[`item.category_id`]="{ item }">
                      {{ item.set_id ? 'Zestaw' : 'Przedmiot' }}
                    </template>

                    <!-- <template v-slot:[`item.item_type`]="{ item }">
                      {{ itemTypeToText(item.item_type) }}
                    </template> -->

                    <template v-slot:[`item.quantity`]="{ item }">
                      {{ item.quantity }}
                    </template>

                    <template v-slot:[`item.name`]="{ item }">
                      <template v-if="item.set_id">
                        {{ `${item.name}` }}
                        ({{
                          categories.sets.length &&
                          categories.sets.find(el => el.category_id == item.category_id)
                            ? categories.sets.find(el => el.category_id == item.category_id).name
                            : '?'
                        }})
                      </template>

                      <template v-else-if="item.variants.length <= 1">
                        {{ `${item.name}` }}
                        ({{
                          categories.items.length
                            ? categories.items.find(el => el.category_id == item.category_id).name
                            : '?'
                        }})
                        {{ '' }}
                        {{ variantFormatName(item.variants[item.selectedVariant]) }}
                      </template>

                      <template v-else>
                        {{ `${item.name}` }}
                        ({{
                          categories.items.length
                            ? categories.items.find(el => el.category_id == item.category_id).name
                            : '?'
                        }})
                        {{ '/' }}
                        {{ variantFormatName(item.variants[item.selectedVariant]) }}
                      </template>
                    </template>

                    <template v-slot:[`item.color`]="{ item }">
                      <template
                        v-if="item.set_id || item.variants[item.selectedVariant].color.length <= 1"
                      >
                        {{
                          item.set_id || !item.variants[item.selectedVariant].color.length
                            ? ''
                            : item.selectedColor
                        }}
                      </template>

                      <template v-else>
                        {{ item.selectedColor }}
                      </template>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                      <td v-if="item.set_id" :colspan="headers.length" class="ma-n1 pa-0">
                        <v-data-table
                          :headers="stepData[1].fields.innderHeaders"
                          :items="item.items"
                          disable-filtering
                          disable-sort
                          hide-default-footer
                          disable-pagination
                          dense
                          no-data-text="Brak przypisanych przedmiotów"
                          :items-per-page="-1"
                          style="padding-left: 12%"
                        >
                          <template v-slot:[`item.quantity`]="{ item }">
                            {{ item.quantity }}
                          </template>

                          <template v-slot:[`item.name`]="{ item }">
                            <template v-if="item.item.variants.length <= 1">
                              {{ `${item.item.name}` }}
                              ({{
                                categories.items.length
                                  ? categories.items.find(
                                      el => el.category_id == item.item.category_id
                                    ).name
                                  : '?'
                              }})
                              {{ '' }}
                              {{ variantFormatName(item.item.variants[item.item.selectedVariant]) }}
                            </template>

                            <template v-else>
                              {{ `${item.item.name}` }}
                              ({{
                                categories.items.length
                                  ? categories.items.find(
                                      el => el.category_id == item.item.category_id
                                    ).name
                                  : '?'
                              }})
                              {{ variantFormatName(item.item.variants[item.item.selectedVariant]) }}
                            </template>
                          </template>

                          <template v-slot:[`item.color`]="{ item }">
                            <template
                              v-if="item.item.variants[item.item.selectedVariant].color.length <= 1"
                            >
                              {{ item.selectedColor }}
                            </template>

                            <template v-else>
                              {{ item.item.selectedColor }}
                            </template>
                          </template>
                        </v-data-table>
                      </td>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <br />
              <v-row justify="center" no-gutters class="text--primary font-weight-medium">
                <v-col cols="12" sm="12" md="12" lg="12" align="end">
                  {{ this.stepData[1].fields.priceIsAccepted ? 'Cena:' : 'Cena*:' }}
                  {{ stepData[5].fields.price }} zł
                </v-col>

                <v-col
                  v-if="stepData[0].fields.paid_collection !== 'warehouse'"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  align="end"
                >
                  {{
                    stepData[0].fields.paid_collection === 'manufactory'
                      ? 'Odbiór od producenta:'
                      : 'Odbiór z wyznaczonego adresu:'
                  }}
                  {{ stepData[5].fields.extra }} zł
                </v-col>

                <br />
                <v-col
                  v-if="!this.stepData[1].fields.priceIsAccepted"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  align="start"
                  class="text-caption text--secondary font-weight-light mt-2"
                >
                  *Zlecenie zawiera przedmioty niewycenione przez administratora, w związku z czym
                  podana cena jest orientacyjna!
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>

      <v-card-actions class="indent-1">
        <v-btn
          v-if="backBtnText"
          color="amber accent-3"
          class="grey--text text--darken-4"
          min-width="100"
          outlined
          v-tilt
          x-large
          @click="backBtn"
        >
          {{ backBtnText }}
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          min-width="100"
          v-tilt
          x-large
          @click="nextBtn"
        >
          {{ nextBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- confirm dialog -->
    <AddItemSetToTransportDialog
      v-model="addItemSetToTransportDialog"
      :items="items"
      :sets="sets"
      :categories="categories"
      @info="infoSnackBar"
      @add="addSelectedToList"
    ></AddItemSetToTransportDialog>

    <!-- add/edit item dialog -->
    <AddEditItemDialog
      v-model="addEditItemDialog"
      :categories="categories.items"
      :singleVariant="true"
      :permissions="permissions"
      @submit="addNewItemToList"
    ></AddEditItemDialog>

    <!-- confirm dialog -->
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>

    <!-- snackbar -->
    <InfoSnackBar v-model="info"></InfoSnackBar>

    <ProgressCircularDialog v-model="loadingScreenDialog"></ProgressCircularDialog>
  </v-container>
</template>

<script>
  import moment from 'moment';
  import urljoin from 'url-join';
  import axios from 'axios';
  import store from '../../store';

  import { mask } from 'vue-the-mask';
  import vue2Dropzone from 'vue2-dropzone';
  import { VueTelInput } from 'vue-tel-input';

  import ConfirmDialog from '../../components/Confirm.vue';
  import InfoSnackBar from '../../components/InfoSnackBar.vue';
  import AddEditItemDialog from '../../components/PriceTable/AddEditItemDialog.vue';
  import AddItemSetToTransportDialog from '../../components/AddTransport/AddItemSetToTransportDialog.vue';
  import ProgressCircularDialog from '../../components/ProgressCircularDialog.vue';

  export default {
    name: 'AddV3',
    components: {
      ConfirmDialog,
      InfoSnackBar,
      ProgressCircularDialog,
      AddEditItemDialog,
      AddItemSetToTransportDialog,

      vueDropzone: vue2Dropzone,
      VueTelInput,
    },
    directives: {
      mask,
    },
    data: () => ({
      info: null,
      loadingScreenDialog: false,
      addItemSetToTransportDialog: false,
      addEditItemDialog: false,

      customer: {},
      manufactories: [],
      categories: { items: [], sets: [] },
      items: [],
      sets: [],

      rules: {
        notEmpty: [v => !!v || 'Pole jest wymagane'],
        postcode: [
          v => !!v || 'Pole jest wymagane',
          v => /^[0-9]{2}-[0-9]{3}$/.test(v) || 'Wprowadź poprawny kod pocztowy',
        ],
        validNIP: [
          v => !!v || 'Pole jest wymagane',
          v => {
            const NIP = v.replace(/[\ \-]/gi, '');
            const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
            const controlNumber = parseInt(NIP.substring(9, 10));

            let sum = 0;
            for (let i = 0; i < weight.length; i++) {
              sum += parseInt(NIP.substr(i, 1)) * weight[i];
            }

            if (sum % 11 !== controlNumber) return false;
            else return true;
          },
        ],
        quantity: [
          v => !!v || 'Pole jest wymagane',
          v => /^[1-9][0-9]{0,3}$/.test(v) || 'Wybierz z przedziału 1-9999',
        ],
        moneyToCollect: [
          v => !!v || 'Pole jest wymagane',
          v =>
            /^(0|([1-9][0-9]{0,7})|(0[\.\,][0-9]{1,2})|([1-9][0-9]{0,7}[\.\,][0-9]{1,2}))$/.test(v) ||
            'Niepoprawna cena',
        ],
      },

      step: 0,
      stepData: [
        {
          validForm: false,
          title: 'Informacje o przesyłce',
          icon: 'mdi-information ',
          fields: {
            payed_in_advance: true,
            paid_collection: 'warehouse',
            pickup_date: null,
            manufactory: null,
            money_to_collect: '',
          },
        },
        {
          validForm: false,
          title: 'Szczegóły zlecenia',
          icon: 'mdi-package-variant',
          fields: {
            items: [],
            sets: [],
            expanded: [],
            headers: [
              {
                text: 'Typ',
                value: 'category_id',
                align: 'center',
                width: '98px',
              },
              // {
              //   text: 'Typ',
              //   value: 'item_type',
              //   align: 'center',
              //   width: '98px',
              // },
              {
                text: 'Ilość',
                value: 'quantity',
                align: 'center',
                width: '92px',
              },
              {
                text: 'Nazwa / Wariant / SKU',
                value: 'name',
                align: 'center',
                width: '70%',
              },
              {
                text: 'Wybarwienie',
                value: 'color',
                align: 'center',
                width: '30%',
              },
              {
                text: '',
                value: 'data-table-expand',
                width: '78px',
              },
            ],
            innderHeaders: [
              {
                text: 'Ilość',
                value: 'quantity',
                align: 'center',
                width: '92px',
              },
              {
                text: 'Nazwa / Wariant / SKU',
                value: 'name',
                align: 'center',
                width: '70%',
              },
              {
                text: 'Wybarwienie',
                value: 'color',
                align: 'center',
                width: '30%',
              },
            ],
            extension: '',
            info: '',
            mediaIDs: [],
          },
        },
        {
          validForm: false,
          title: 'Adres odbioru',
          icon: 'mdi-truck-delivery mdi-flip-h',
          fields: {
            name: '',
            validPhone_1: false,
            errorMessage_1: '',
            iso2_1: 'PL',
            dialCode_1: '48',
            phone_1: '',
            validPhone_2: true,
            errorMessage_2: '',
            iso2_2: 'PL',
            dialCode_2: '48',
            phone_2: '',
            city: '',
            postcode: '',
            street: '',
          },
        },
        {
          validForm: false,
          title: 'Adres dostawy',
          icon: 'mdi-truck-delivery',
          fields: {
            name: '',
            validPhone_1: false,
            errorMessage_1: '',
            iso2_1: 'PL',
            dialCode_1: '48',
            phone_1: '',
            validPhone_2: true,
            errorMessage_2: '',
            iso2_2: 'PL',
            dialCode_2: '48',
            phone_2: '',
            city: '',
            postcode: '',
            street: '',
          },
        },
        {
          validForm: false,
          title: 'Dane do faktury',
          icon: 'mdi-script-text',
          fields: {
            NIP: '',
            name: '',
            city: '',
            postcode: '',
            street: '',
          },
        },
        {
          validForm: true,
          title: 'Podsumowanie zamówienia',
          icon: 'mdi-receipt',
          fields: {
            price: null,
            extra: null,
          },
        },
      ],

      dropzoneOptions: {
        url: urljoin(store.state.url, '/api/media/upload'),
        thumbnailWidth: 150,
        maxFiles: 6,
        maxFilesize: 6,
        dictDefaultMessage: 'Dodaj załączniki (jpg, png, pdf, doc)',
        // addRemoveLinks: true, // it do not remove ids from model !
        acceptedFiles:
          'image/*,.pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
      telOptions: {
        input_1: {
          autocomplete: 'on',
          autofocus: false,
          describedby: '',
          id: null,
          maxlength: 25,
          name: 'telephone',
          placeholder: 'Numer telefonu',
          readonly: false,
          required: true,
          tabindex: 0,
          type: 'tel',
          styleClasses: '',
        },
        input_2: {
          autocomplete: 'on',
          autofocus: false,
          describedby: '',
          id: null,
          maxlength: 25,
          name: 'telephone',
          placeholder: 'Numer telefonu (dodatkowy)',
          readonly: false,
          required: true,
          tabindex: 0,
          type: 'tel',
          styleClasses: '',
        },
        dropdown: {
          showDialCodeInSelection: true,
          showDialCodeInList: true,
          showFlags: true,
          tabindex: 0,
        },
        avaliableCountries: [
          ['Albania (Shqipëri)', 'al', '355'],
          ['Andorra', 'ad', '376'],
          ['Armenia (Հայաստան)', 'am', '374'],
          ['Austria (Österreich)', 'at', '43'],
          ['Azerbaijan (Azərbaycan)', 'az', '994'],
          ['Belarus (Беларусь)', 'by', '375'],
          ['Belgium (België)', 'be', '32'],
          ['Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '387'],
          ['Bulgaria (България)', 'bg', '359'],
          ['Croatia (Hrvatska)', 'hr', '385'],
          ['Cyprus (Κύπρος)', 'cy', '357'],
          ['Czech Republic (Česká republika)', 'cz', '420'],
          ['Denmark (Danmark)', 'dk', '45'],
          ['Estonia (Eesti)', 'ee', '372'],
          ['Finland (Suomi)', 'fi', '358'],
          ['France', 'fr', '33'],
          ['Georgia (საქართველო)', 'ge', '995'],
          ['Germany (Deutschland)', 'de', '49'],
          ['Greece (Ελλάδα)', 'gr', '30'],
          ['Hungary (Magyarország)', 'hu', '36'],
          ['Iceland (Ísland)', 'is', '354'],
          ['Ireland', 'ie', '353'],
          ['Italy (Italia)', 'it', '39'],
          ['Kazakhstan (Казахстан)', 'kz', '7'],
          ['Kosovo', 'xk', '383'],
          ['Latvia (Latvija)', 'lv', '371'],
          ['Liechtenstein', 'li', '423'],
          ['Lithuania (Lietuva)', 'lt', '370'],
          ['Luxembourg', 'lu', '352'],
          ['Malta', 'mt', '356'],
          ['Moldova (Republica Moldova)', 'md', '373'],
          ['Monaco', 'mc', '377'],
          ['Montenegro (Crna Gora)', 'me', '382'],
          ['Netherlands (Nederland)', 'nl', '31'],
          ['Macedonia (FYROM) (Македонија)', 'mk', '389'],
          ['Norway (Norge)', 'no', '47'],
          ['Poland (Polska)', 'pl', '48'],
          ['Portugal', 'pt', '351'],
          ['Romania (România)', 'ro', '40'],
          ['Russia (Россия)', 'ru', '7'],
          ['San Marino', 'sm', '378'],
          ['Serbia (Србија)', 'rs', '381'],
          ['Slovakia (Slovensko)', 'sk', '421'],
          ['Slovenia (Slovenija)', 'si', '386'],
          ['Spain (España)', 'es', '34'],
          ['Sweden (Sverige)', 'se', '46'],
          ['Switzerland (Schweiz)', 'ch', '41'],
          ['Turkey (Türkiye)', 'tr', '90'],
          ['Ukraine (Україна)', 'ua', '380'],
          ['United Kingdom', 'gb', '44'],
          ['Vatican City (Città del Vaticano)', 'va', '39'],

          ['United States', 'us', '1'],
          ['Canada', 'ca', '1'],
          ['Australia', 'au', '61'],
          ['Japan (日本)', 'jp', '81'],
          ['China (中国)', 'cn', '86'],
          ['South Korea (대한민국)', 'kr', '82'],
        ].map(([name, iso2, dialCode]) => ({
          name,
          iso2: iso2.toUpperCase(),
          dialCode,
        })),
      },
    }),
    computed: {
      isMobile: function () {
        return this.$vuetify.breakpoint.xs;
      },
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      nextBtnText: function () {
        switch (this.step) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
            return 'Dalej';
          case 5:
            return 'Dodaj';
          default:
            return '';
        }
      },
      backBtnText: function () {
        switch (this.step) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            return 'Wstecz';
          default:
            return '';
        }
      },

      addedItemsAndSets: function () {
        let counter = 0;
        return this.stepData[1].fields.sets
          .concat(this.stepData[1].fields.items)
          .map(elem => Object.assign(elem, { index: counter++ }));
      },
      permissions: function () {
        return {
          hard_furniture: 1,
          upholstered_furniture: 1,
          chairs: 1,
          tables: 1,

          paid_collection: 0,
        };
        // return {
        //   hard_furniture: this.customer.hard_furniture || 1,
        //   upholstered_furniture: this.customer.upholstered_furniture || 0,
        //   chairs: this.customer.chairs || 0,
        //   tables: this.customer.tables || 0,

        //   paid_collection: this.customer.paid_collection || 0,
        // };
      },
    },
    watch: {},
    methods: {
      infoSnackBar(info) {
        this.info = {
          message: info.message || info.content || '',
          status: info.status || info.type || 'error',
          timeout: info.timeout,
        };
      },

      nextBtn: function () {
        let isValid = true;
        switch (this.step) {
          case 0:
            if (!this.$refs.zeroForm.validate()) isValid = false;
            break;
          case 1:
            if (!this.$refs.oneForm.validate()) isValid = false;
            if (!(this.stepData[1].fields.items.length + this.stepData[1].fields.sets.length))
              return this.infoSnackBar({ message: 'Lista przedmiotów zlecenia jest pusta' });
            break;
          case 2:
            if (!this.$refs.twoForm.validate()) isValid = false;
            if (!this.stepData[2].fields.validPhone_1) {
              this.stepData[2].fields.errorMessage_1 = 'Niepoprawny numer telefonu';
              isValid = false;
            }
            if (!this.stepData[2].fields.validPhone_2) {
              this.stepData[2].fields.errorMessage_2 = 'Niepoprawny numer telefonu';
              isValid = false;
            }
            break;
          case 3:
            if (!this.$refs.threeForm.validate()) isValid = false;
            if (!this.stepData[3].fields.validPhone_1) {
              this.stepData[3].fields.errorMessage_1 = 'Niepoprawny numer telefonu';
              isValid = false;
            }
            if (!this.stepData[3].fields.validPhone_2) {
              this.stepData[3].fields.errorMessage_2 = 'Niepoprawny numer telefonu';
              isValid = false;
            }
            break;
          case 4:
            if (!this.$refs.fourForm.validate()) isValid = false;
            break;
          case 5:
            if (!this.$refs.fiveForm.validate()) isValid = false;
            break;
        }

        if (!isValid) return this.infoSnackBar({ message: 'Uzupełnij wszystkie wymagane dane' });

        if (this.step === 1) this.getPrice();
        if (this.step === 1 && this.stepData[0].fields.paid_collection !== 'pickup')
          return (this.step += 2);

        if (this.step === 5) return this.createOrder();

        this.step++;
      },
      backBtn: function () {
        if (this.step === 3 && this.stepData[0].fields.paid_collection !== 'pickup')
          return (this.step -= 2);

        this.step--;
      },
      doNotAllowWeekendsAndTodayAfter15(date) {
        const momentDate = moment(date);
        if (
          momentDate.isBefore(moment(), 'day') ||
          (momentDate.isSame(moment(), 'day') && moment().hour() >= 15)
        )
          return false;

        const day = momentDate.isoWeekday();
        return day !== 6 && day !== 7;
      },

      onSend: function (file, xhr, formData) {
        formData.append('token', this.$store.state.token);
      },
      onSuccess: function (file, response) {
        this.mediaIds.push(response.mediaId);
      },

      itemTypeToText: function (item_type) {
        switch (item_type) {
          case 'chairs':
            return 'Krzesła';
          case 'tables':
            return 'Stoły';
          case 'hard_furniture':
            return 'M. paczkowane';
          case 'upholstered_furniture':
            return 'Inne';
          default:
            return '';
        }
      },

      addSelectedToList: function (obj, type) {
        if (type === 'sets') {
          this.stepData[1].fields.sets.push(obj);
          this.stepData[1].fields.expanded.push(obj);
        } else this.stepData[1].fields.items.push(obj);

        this.addItemSetToTransportDialog = false;
      },
      addNewItemToList: async function (item) {
        if (
          await this.$refs.confirmDialog.open(
            `Zapisz przedmiot`,
            `Czy chcesz dodać utworzony przedmiot do zakładki MOJE PRODUKTY?`,
            { cancelButtonText: 'Nie', acceptButtonText: 'Tak' }
          )
        ) {
          this.loadingScreenDialog = true;

          const result = await axios({
            url: this.$store.state.url + '/api/priceTable/add-item',
            data: {
              token: this.$store.state.token,
              item: item,
            },
            method: 'POST',
          }).catch(err => {
            if (err.response && err.response.status !== 500) {
              this.infoSnackBar({
                content:
                  err.response.data.message ||
                  'Nieoczekiwany błąd! Przedmiot nie został dodany do listy twoich produktów.',
                type: 'error',
              });
            } else {
              this.infoSnackBar({
                content: 'Nieoczekiwany błąd! Przedmiot nie został dodany do listy twoich produktów.',
                type: 'error',
              });
            }
          });

          let that = this;
          setTimeout(function () {
            that.loadingScreenDialog = false;
          }, 800);
          if (!result) return;

          if (result.data.status == 200) {
            this.items.push(result.data.item);
            this.infoSnackBar({
              content: result.data.message,
              type: 'success',
            });
          } else
            return this.infoSnackBar({
              content: result.data.message,
              type: 'error',
            });
        }

        this.stepData[1].fields.items.push({
          ...item,
          quantity: 1,
          selectedColor: item.variants[0].color[0] || '',
          selectedVariant: 0,
        });

        this.addEditItemDialog = false;
      },
      removeFromList: async function (objType = 'items', obj) {
        if (
          !(await this.$refs.confirmDialog.open(
            `Usuń ${objType === 'items' ? 'przedmiot' : 'zestaw'}`,
            `Czy chcesz usunąć ${objType === 'items' ? 'przedmiot' : 'zestaw'} z listy?`,
            { color: 'red' }
          ))
        )
          return;

        const index = this.stepData[1].fields[objType].findIndex(elem => elem == obj);
        if (index !== -1) this.stepData[1].fields[objType].splice(index, 1);
      },
      changeVariant: function (value, item) {
        // item.quantity = 1;
        item.selectedColor = item.variants[value].color[0] || '';
      },

      telValidationSetter: function (phoneObject, index, order, isRequired = true) {
        if (isRequired) {
          if (phoneObject.valid == undefined) {
            this.stepData[index].fields[`errorMessage_${order}`] = '';
            this.stepData[index].fields[`validPhone_${order}`] = false;
          } else if (phoneObject.valid == false) {
            this.stepData[index].fields[`errorMessage_${order}`] = 'Niepoprawny numer telefonu';
            this.stepData[index].fields[`validPhone_${order}`] = false;
          } else {
            this.stepData[index].fields[`errorMessage_${order}`] = '';
            this.stepData[index].fields[`validPhone_${order}`] = true;
          }
        } else {
          if (phoneObject.formatted.length && phoneObject.valid == false) {
            this.stepData[index].fields[`errorMessage_${order}`] = 'Niepoprawny numer telefonu';
            this.stepData[index].fields[`validPhone_${order}`] = false;
          } else {
            this.stepData[index].fields[`errorMessage_${order}`] = '';
            this.stepData[index].fields[`validPhone_${order}`] = true;
          }
        }
      },
      telCountryChanged: function (countryObj, index, order) {
        if (countryObj && countryObj.dialCode)
          Object.assign(this.stepData[index].fields, {
            [`dialCode_${order}`]: countryObj.dialCode,
            [`iso2_${order}`]: countryObj.iso2,
          });
      },

      variantFormatName: function (variant) {
        return (
          (variant.variant_name || variant.name || '') + (variant.SKU ? ` / ${variant.SKU}` : '')
        );
      },

      getSelfData: function () {
        axios({
          url: this.$store.state.url + '/api/customer/self_data',
          data: {
            token: this.$store.state.token,
            type: 'join',
            order: 'left',
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200 && resp.data.customer) {
              if (resp.data.customer.company)
                resp.data.customer.invoice_date = {
                  name: resp.data.customer.name,
                  NIP: resp.data.customer.NIP,
                  street: resp.data.customer.address,
                  postcode: resp.data.customer.postcode,
                  city: resp.data.customer.city,
                };
              else this.stepData[4].fields.editInvoice = true;

              this.customer = resp.data.customer;
              if (resp.data.customer.invoice_date)
                this.stepData[4].fields = resp.data.customer.invoice_date;
            } else
              this.infoSnackBar({
                message: resp.data.message || 'Błąd serwera, załaduj ponownie stronę',
              });
          })
          .catch(() =>
            this.infoSnackBar({
              message: 'Błąd serwera, załaduj ponownie stronę',
            })
          );
      },
      getCategories: function () {
        axios({
          url: this.$store.state.url + '/api/priceTable/get-categories',
          data: {
            token: this.$store.state.token,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) this.categories = resp.data.categories;
            else
              this.infoSnackBar({
                message: resp.data.message || 'Błąd serwera, załaduj ponownie stronę',
              });
          })
          .catch(() =>
            this.infoSnackBar({
              message: 'Błąd serwera, załaduj ponownie strone',
            })
          );
      },
      getItems: function () {
        axios({
          url: this.$store.state.url + '/api/priceTable/get-items',
          data: {
            token: this.$store.state.token,
            extended: true,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) this.items = resp.data.items;
            else
              this.infoSnackBar({
                message: resp.data.message || 'Błąd serwera, załaduj ponownie stronę',
              });
          })
          .catch(() =>
            this.infoSnackBar({
              message: 'Błąd serwera, załaduj ponownie strone',
            })
          );
      },
      getSets: function () {
        axios({
          url: this.$store.state.url + '/api/priceTable/get-sets',
          data: {
            token: this.$store.state.token,
            extended: true,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) {
              this.sets = resp.data.sets;

              // it can be used with `exteded` false (but need to wait for getItems)
              // for (const set of this.sets) {
              //   const setItems = this.items.filter(elem =>
              //     set.items.map(elem => elem.item_id).includes(elem.item_id)
              //   );
              //   for (const setItem of set.items)
              //     setItem.item = setItems.find(elem => elem.item_id === setItem.item_id);
              // }
            } else
              this.infoSnackBar({
                message: resp.data.message || 'Błąd serwera, załaduj ponownie stronę',
              });
          })
          .catch(() =>
            this.infoSnackBar({
              message: 'Błąd serwera, załaduj ponownie strone',
            })
          );
      },
      getManufactories: function () {
        axios({
          url: this.$store.state.url + '/api/priceTable/get-manufactories',
          data: {
            token: this.$store.state.token,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) {
              this.manufactories = resp.data.manufactories;
              this.stepData[0].fields.manufactory = this.manufactories[0] || null;
            } else
              this.infoSnackBar({
                message: resp.data.message || 'Błąd serwera, załaduj ponownie stronę',
              });
          })
          .catch(() =>
            this.infoSnackBar({
              message: 'Błąd serwera, załaduj ponownie strone',
            })
          );
      },

      getPrice: function () {
        this.stepData[1].fields.priceIsAccepted = this.stepData[1].fields.items
          .map(elem => elem.isAccepted)
          .concat(
            this.stepData[1].fields.sets.flatMap(elem => elem.items.map(elem => elem.item.isAccepted))
          )
          .every(elem => elem == 1 || elem == 'true' || elem == true);

        axios({
          url: this.$store.state.url + '/api/transport/getPriceV2',
          data: {
            token: this.$store.state.token,
            zeroForm: this.stepData[0].fields,
            oneForm: {
              info: this.stepData[1].fields.info,
              extension: this.stepData[1].fields.extension,
              media_ids: this.stepData[1].fields.mediaIds,
              items: this.stepData[1].fields.items.map(elem => ({
                item_id: elem.item_id,
                item_type: elem.item_type,
                category_id: elem.category_id,
                name: elem.name,
                quantity: elem.quantity,
                selectedColor: elem.selectedColor,
                selectedVariant: elem.variants[elem.selectedVariant],
              })),
              sets: this.stepData[1].fields.sets.map(elem => ({
                set_id: elem.set_id,
                quantity: elem.quantity,
                items: elem.items.map(elem => ({
                  item_id: elem.item.item_id,
                  item_type: elem.item.item_type,
                  category_id: elem.item.category_id,
                  name: elem.item.name,
                  quantity: elem.quantity,
                  selectedColor: elem.item.selectedColor,
                  selectedVariant: elem.item.variants[elem.item.selectedVariant],
                })),
              })),
            },
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status) {
              this.stepData[5].fields.price = resp.data.price || '0.00';
              this.stepData[5].fields.extra = resp.data.extra || '0.00';
            }
          })
          .catch(() => {});
      },
      createOrder: function () {
        const tmp = {
          zeroForm: this.stepData[0].fields,
          oneForm: {
            info: this.stepData[1].fields.info,
            extension: this.stepData[1].fields.extension,
            media_ids: this.stepData[1].fields.mediaIds,
            items: this.stepData[1].fields.items.map(elem => ({
              item_id: elem.item_id,
              item_type: elem.item_type,
              category_id: elem.category_id,
              name: elem.name,
              quantity: elem.quantity,
              selectedColor: elem.selectedColor,
              selectedVariant: elem.variants[elem.selectedVariant],
            })),
            sets: this.stepData[1].fields.sets.map(elem => ({
              set_id: elem.set_id,
              quantity: elem.quantity,
              items: elem.items.map(elem => ({
                item_id: elem.item.item_id,
                item_type: elem.item.item_type,
                category_id: elem.item.category_id,
                name: elem.item.name,
                quantity: elem.quantity,
                selectedColor: elem.item.selectedColor,
                selectedVariant: elem.item.variants[elem.item.selectedVariant],
              })),
            })),
          },
          twoForm: this.stepData[2].fields,
          threeForm: this.stepData[3].fields,
          fourForm: this.stepData[4].fields,
        };

        this.loadingScreenDialog = true;
        axios({
          url: this.$store.state.url + '/api/transport/addV2',
          data: {
            token: this.$store.state.token,
            ...tmp,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200)
              this.$router.push({
                name: 'singleTransport',
                params: {
                  id: resp.data.itemId,
                },
              });
            else
              this.infoSnackBar({
                message: resp.data.message,
              });

            this.loadingScreenDialog = false;
          })
          .catch(() => {
            this.loadingScreenDialog = false;
            this.infoSnackBar({
              message: 'Błąd serwera, załaduj ponownie strone',
            });
          });
      },
    },
    mounted() {
      this.getSelfData();
      this.getManufactories();
      this.getCategories();
      this.getItems();
      this.getSets();
    },
    created() {
      // set first posibble day
      this.stepData[0].fields.pickup_date = (() => {
        for (const i in [0, 1, 2, 3, 4, 5, 6, 7])
          if (this.doNotAllowWeekendsAndTodayAfter15(moment().add(i, 'days')))
            return moment().add(i, 'days').format('YYYY-MM-DD');
      })();
    },
  };
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style src="vue2-dropzone/dist/vue2Dropzone.min.css"></style>
<style>
  .dz-error-message {
    top: 72px !important;
  }
  .vue-dropzone > .dz-preview .dz-remove {
    left: 0px;
    right: 0px;
    margin-left: 15px;
    margin-right: 15px;
  }
</style>
<style scoped>
  .indent-1 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .indent-2 {
    padding-left: 32px;
    padding-right: 32px;
  }
</style>
